@font-face {
  font-family: "satoshi";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Regular.otf) format("woff");
}

@font-face {
  font-family: "SatoshiBold";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Medium.otf) format("woff");
}

@font-face {
  font-family: "SatoshiBolder";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Bold.otf) format("woff");
}

@font-face {
  font-family: "satoshi";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Regular.otf) format("woff");
}

@font-face {
  font-family: "satoshiBold";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Medium.otf) format("woff");
}

@font-face {
  font-family: "satoshiBolder";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Bold.otf) format("woff");
}

body {
  font-family: "satoshi" !important;
}

.form-input {
  color: "#5B2E4F";
  border-radius: 6px;
  border: 0.17px solid rgba(91, 46, 79, 1) !important;
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f) !important;
  padding: 15px 10px;
  width: 100%;
  font-size: 13px;
}

.input_container {
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f) !important;
  color: "#5B2E4F";
  border-radius: 6px;
  border: 0.17px solid rgba(91, 46, 79, 1) !important;
  width: 100%;
  font-size: 13px;
  padding: 5px 10px;
}

.form-input::placeholder {
  color: #33343d;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.5;
}

.form-input:focus,
.form-textarea {
  outline: none;
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f);
  /* This will remove the default focus outline */
}

.containerClassName {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
}
.previousClassName,
.nextClassName {
  color: #2e3192;
  font-size: 15px;
  padding: 10px 15px;
  border: 1px solid #e4e8f2;
  border-radius: 6px;
  margin: 7px;
}
.nextClassName:hover,
.previousClassName:hover {
  background-color: #efeaed;
  transition: all 0.2s linear;
}

.pageLinkClassName {
  margin: 8px;
  font-size: 15px;
  color: #666666;
  font-weight: 700;
  font-family: satoshiBolder;
  opacity: 0.5;
}
.activeLinkClassName {
  color: #666666;
  opacity: 1;
}

/* Loader Style Starts Here*/

.loader {
  font-size: 40px;
  font-family: "satoshi" !important;
  color: #5b2e4f;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  position: relative;
}
.loader:after {
  content: "HerVest";
  position: relative;
  z-index: 5;
}
.loader:before {
  content: "";
  height: 6px;
  border: 1px solid;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(#5b2e4f 100%, transparent 0) no-repeat;
  background-size: 0% auto;
  animation: 10s lineGrow linear infinite;
}

@keyframes lineGrow {
  to {
    background-size: 100% auto;
  }
}

.loginBg {
  background-image: url(../src/assets/hervest-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: "100vh";
  width: "100vw";
}
